import React, { Suspense, useEffect } from 'react';
import { useRoutes } from 'hookrouter';
import {connect, useDispatch} from 'react-redux';
import IndexPage from '../pages/index';
import NotFound from '../pages/notFound';
import RegisterIndividualPage from '../pages/register-individual';
import RetrieveGroupApplicationPage from '../pages/retrieve-group-application';
import RetrieveIndividualApplicationPage from '../pages/retrieve-individual-application';
import UserUploadsPage from '../pages/user-uploads';
import RegisterGroupPage from '../pages/register-group';
import AgentManagerPage from '../pages/agent-manager';
import PrivacyPage from '../pages/privacy';
import RegulationsPage from '../pages/regulations';
import VisaStatusPage from '../pages/visa-status';
import UpdateRejectedDataBody from '../pages/update-rejected-data';
import CONSTANTS from '../common/constants';
import { updateLanguageWithConstants, getLanguageValue } from '../common/functions';

import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
import { ACTIONS } from "../redux/actions";

let LOOP_LIMIT = 1000;

function RouterComponent(props) {
    // const IndexPageLazy = React.lazy(() => import("../pages/index"));
    // const FaqPageLazy = React.lazy(() => import("../pages/faq"));
    // const RegisterIndividualPageLazy = React.lazy(() => import("../pages/register-individual"));

    const dispatch = useDispatch();
    // const routes = {
    //     // '/': () => (<Suspense fallback={null}><IndexPageLazy /></Suspense>),
    //     // '/faq': () => (<Suspense fallback={null}><FaqPageLazy /></Suspense>),
    //     // '/register-visa-for-individual': () => (<Suspense fallback={null}><RegisterIndividualPageLazy /></Suspense>)
    //     '/': () => (<IndexPage />),
    //     '/faq': () => (<FaqPage />),
    //     '/faq/*': () => (<FaqPage />),
    //     '/register-visa-for-individual*': () => (<RegisterIndividualPage />),
    //     // '/register-visa-for-individual/*': () => (<RegisterIndividualPage />)
    // };

    useEffect(() => {
        // fix language template vars once
        if (getLanguageValue('regulations-content-html').indexOf('{PRICES_') === -1) {
            return;
        }
        let intervalId = setInterval(() => {
            if (!CONSTANTS.PRICES) {
                return;
            }
            LOOP_LIMIT--;
            if (LOOP_LIMIT <= 0) {
                // prevent endless loop
                clearInterval(intervalId);
            }
            if (getLanguageValue('regulations-content-html').indexOf('{PRICES_') > -1) {
                updateLanguageWithConstants();
            }
            if (getLanguageValue('regulations-content-html').indexOf('{PRICES_') === -1) {
                clearInterval(intervalId);
                dispatch({ type: ACTIONS.SET_LANGUAGE, payload: window.LANGUAGE.key + '_tmp' });
                setTimeout(() => {
                    dispatch({ type: ACTIONS.SET_LANGUAGE, payload: window.LANGUAGE.key });
                }, 50);
            }
        }, 50);
    }, [ props.languageKey ]);
    //const routeResult = useRoutes(routes);

    //return routeResult || <NotFound />;

    if (props.routerRedirect) {
        dispatch({
            type: ACTIONS.ROUTER_UNSET_REDIRECT
        });
        return <Router><Redirect to={props.routerRedirect} push={true} /></Router>;
    }

    return <Router>
        <Switch>
            <Route exact path="/">
                <IndexPage />
            </Route>
            <Route path="/retrieve-group-application">
                <RetrieveGroupApplicationPage />
            </Route>
            <Route path="/retrieve-individual-application">
                <RetrieveIndividualApplicationPage />
            </Route>
            <Route path="/retrieve-group-application/continue-process">
                <RetrieveGroupApplicationPage />
            </Route>
            <Route path="/retrieve-individual-application/continue-process">
                <RetrieveIndividualApplicationPage />
            </Route>
            <Route path="/register-visa-for-individual">
                <RegisterIndividualPage />
            </Route>
            <Route path="/register-visa-for-group">
                <RegisterGroupPage />
            </Route>
            <Route path="/privacy">
                <PrivacyPage />
            </Route>
            <Route path="/regulations">
                <RegulationsPage />
            </Route>
            <Route path="/visa-status/:id/">
                <VisaStatusPage />
            </Route>
            <Route path="/update-rejected-data">
                <UpdateRejectedDataBody />
            </Route>
            <Route path="/agent-manager/:id">
                <AgentManagerPage />
            </Route>
            <Route path="/user-uploads/:id">
                <UserUploadsPage />
            </Route>
            <Route>
                {() => { return <Redirect to={''} push={true} />; }}
            </Route>
        </Switch>
    </Router>;
}

const RouterConnected = connect(
    (state) => ({
        languageKey: state.languageKey,
        routerRedirect: state.routerRedirect
    }),
    {})(RouterComponent);

export default RouterConnected;
