import React from 'react';
import styled from 'styled-components';
import {ZIndices} from '../common/zIndices';
import CONSTANTS from '../common/constants';
import { ElfsightWidget } from 'react-elfsight-widget';
import { useLocation } from 'react-router-dom';

function StepBarComponent(props) {
    const barItems = props.items;
    const activeStage = props.activeStage;
    const location = useLocation();

    return <div><StepBarStyle className={'progress-bar-container ' + (props.className || '')} itemPercentage={props.itemPercentage}>
        {barItems.map(function(barItem, barItemIndex) {
            return <div key={barItemIndex} className={"progress-cube" + (barItemIndex + 1 == activeStage ? ' active' : '')}>
                {barItem.icon}
                {barItems.length - 1 !== barItemIndex && <div className={"cube-cross-line index-" + barItemIndex + (barItem.active ? ' active' : '')} />}
                <div className="progress-bar-text">{barItem.text}</div>
            </div>;
        })}
    </StepBarStyle>
    {/* location.pathname=="/" &&
    <ElfsightWidget widgetID="dce94112-14f7-4f72-ac62-19493608f956" lazy modern/>*/}</div>;
}

const StepBarStyle = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  max-width: 1100px;
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
    display: block;
    html.rtl & {
      margin-right: 65px;
    }
    html.ltr & {
      margin-left: 65px;
    }
  }
  .progress-cube {
    position: relative;
    flex-grow: 1;
    text-align: center;
    width: ${props => (props.itemPercentage)}%;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      width: auto;
      html.rtl & {
        text-align: right;
      }
      html.ltr & {
        text-align: left;
      }
      margin-bottom: 10px;
    }
    > svg {
      position: relative;
      background-color: #32cd32;
      border-radius: 50%;
      color: white;
    }
  }
  .progress-bar-text {
    color: white;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: inline-block;
      position: relative;
      top: -6px;
      html.rtl & {
        padding-right: 5px;
      }
      html.ltr & {
        padding-left: 5px;
      }
    }
  }
  .cube-cross-line {
    position: absolute;
    height: 5px;
    background-color: #aaa;
    width: calc(100% - 16px);
    top: 10px;
    z-index: ${ZIndices.STEPS_BAR_CROSS_LINE};
    html.rtl & {
      right: calc(50% + 8px);
    }
    html.ltr & {
      left: calc(50% + 8px);
    }
    &.active {
      background-color: #1460aa;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      right: 11px !important;
      top: 24px;
      width: 2px;
      height: 15px;
      html.rtl & {
        right: 11px !important;
      }
      html.ltr & {
        right: unset;
        left: 12px !important;
      }
    }
  }
`;

export default StepBarComponent;
